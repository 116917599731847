.ht-gridmenu {
  padding: rem(10) rem($margin) 0;
  position: relative;

  &__back {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(132);
    background: #2866ff;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    border-radius: rem(16);
    position: relative;
    padding: rem(30) 0;
    z-index: 2;
  }

  &__item {
    flex: 1;
  }

  &__link {
    display: block;
    text-align: center;
    padding: 0 rem($margin);
  }

  &__icon {
    display: block;
    width: rem(92);
    height: rem(92);
    font-size: rem(48);
    border-radius: 50%;
    padding: rem(22);
    line-height: 1;
    margin: 0 auto;
    color: #fff;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    display: block;
    color: #191b27;
    font-size: rem(28);
    font-weight: bold;
    text-align: center;
    margin-top: rem(20);
    line-height: 1.1;
  }

  &__item:nth-child(1) &__icon {
    background: #798cfd;
  }

  &__item:nth-child(2) &__icon {
    background: #f44319;
  }

  &__item:nth-child(3) &__icon {
    background: #f3b919;
  }

  &__item:nth-child(4) &__icon {
    background: #ff6e9a;
  }
}
