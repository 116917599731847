@use "sass:math";
@import "./utils/mixin"; // 全局变量
@import "./utils/variable"; // 全局变量
@import "./utils/normalize"; // 样式初始化
@import "./utils/common"; // 样式初始化

// components plugin
@import "../components/header/ht-header.scss";
@import "../components/ht-notice/ht-notice.scss";
@import "../components/ht-topnav/ht-topnav.scss";
@import "../components/ht-tabbar/ht-tabbar.scss";
@import "../components/ht-cell/ht-cell.scss";
@import "../components/ht-card/ht-card.scss";
@import "../components/ht-table/ht-table.scss";
@import "../components/ht-picker/ht-picker.scss";
@import "../components/ht-gridmenu/ht-gridmenu.scss";
@import "../components/ht-card/ht-message.scss";
@import "../components/ht-mainnav/ht-mainnav.scss";
@import "../components/ht-filter/ht-filter.scss";
@import "../components/ht-modal-filter/ht-modal-filter.scss";
@import "../components/ht-form/ht-form.scss";
@import "../components/ht-modal/ht-modal.scss";

// index style
@import "./page/index";
@import "./page/member";
@import "./page/charge";
@import "./page/loaned";

// @import "./page/team";
// @import "./page/server";
// @import "./page/data";
// @import "../components/media/media-news.scss";
// @import "../components/base/ht-banner/index";
// @import "../components/breadcrumb/breadcrumb.scss";
// @import "../components/title/ht-title.scss";
// @import "../components/drawer/ht-drawer.scss";
// @import "../components/base/ht-banner/ibanner.scss";
// @import "../components/card/ht-card-news.scss";
// @import "../components/ht-card/ht-card-news.scss";
// @import "../components/ht-card/ht-card-team.scss";
// @import "../components/ht-card/ht-card-doings.scss";
// @import "../components/pagination/pagination.scss";
// @import "../components/ht-article/ht-article.scss";
/* 提示 */
.remind {
  &-layer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    padding-top: rem(39);
    padding-right: rem(63);
    transition: 0.35s ease-in-out;
    @include hide;

    &.active {
      @include show(1);
    }
  }

  &-arrow {
    width: rem(149);
    height: rem(173);
  }

  &-point {
    color: #fff;
    font-size: rem(40);
    line-height: 1.3;
    width: rem(399);
    margin-top: rem(48);
    margin-right: rem(72);
    border: rem(4.5) dashed #fff;
    border-radius: rem(80);
    padding: rem(30);
  }

  &-image {
    margin-top: rem(75);
    margin-right: rem(82);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon {
      display: block;
      color: #4c4c4c;
      width: rem(84);
      height: rem(84);
      font-size: rem(48);
      text-align: center;
      line-height: rem(64);
      background-color: #fff;
      margin-right: rem(28);
      border-radius: 50%;
      padding: rem(10);
    }

    .text {
      display: block;
      height: rem(66);
      font-size: rem(28);
      font-weight: 600;
      line-height: rem(66);
      background-color: #dbdbdb;
      border-radius: rem(10);
      padding: 0 rem(30);

      i {
        font-size: rem(54);
        line-height: rem(66);
        display: inline-block;
        vertical-align: top;
        margin-right: rem(20);
      }
    }
  }
}
