.home {
  /* 首页poster */
  &-poster {
    padding: rem(10) rem(24) rem(30);
    background-color: #fff;

    &-item {
      line-height: 0;
      margin-top: rem(20);
      border-radius: rem(12);
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 0;
      padding: rem(60);

      strong {
        display: block;
        @include word(#ffffff, rem(48), 0, rem(75));
      }

      span {
        display: block;
        @include word(#ffffff, rem(28), 0, rem(54));
        opacity: 0.5;
      }
    }
  }

  &-title {
    color: #191b27;
    font-size: rem(32);
    line-height: 1;
    font-weight: 600;
  }

  &-brief {
    @include word(#8a8e9b, rem(24), 0, rem(32));
    margin-top: rem(24);
  }

  &-panel {
    padding: rem(30);
    border-radius: rem(16);
    background-color: #fff;
    margin: rem(20) rem(24) 0;
  }

  &-process {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: rem(30);

    &__item {
      flex: 1;
      text-align: center;
      margin-left: rem(54);

      .name {
        @include word(#191b27, rem(28), 0, 0);
        line-height: 1;
        margin-top: rem(30);
      }

      .icon {
        display: block;
        width: rem(90);
        height: rem(90);
        line-height: 1;
        margin: 0 auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-advance {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: rem(30);

    &__item {
      flex: 1;
      text-align: center;
      margin-left: rem(54);

      .name {
        @include word(#191b27, rem(24), 0, 0);
        line-height: rem(30);
        margin-top: rem(18);
      }

      .icon {
        display: block;
        width: rem(60);
        height: rem(60);
        line-height: 1;
        margin: 0 auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-service {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: rem(30);

    &__item {
      flex: 1;
      color: #e5901a;
      margin-left: rem(20);
      position: relative;

      .back {
        line-height: 0;
        @include image(170, 311, relative);
      }

      .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: rem(30);

        h3 {
          color: currentColor;
          font-size: rem(32);
          line-height: rem(40);
          font-weight: 600;
        }

        p {
          @include word(#8a8e9b, rem(24), 0, rem(40));
          margin-top: rem(8);
        }
      }

      &:first-child {
        color: #0095f0;
        margin-left: 0;
      }
    }
  }
}
