/* 个人中心 */
.member {
  &-head {
    position: relative;
    z-index: 1;
  }

  &-back {
    width: 100%;
    @include image(280, 750, relative);
  }

  &-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: rem(30) rem($margin);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-arrow {
    position: absolute;
    top: rem(64);
    right: rem($margin - 6);
    height: rem(52);
    line-height: rem(52);

    a {
      display: block;
      color: #fff;
      font-size: rem(48);
    }
  }

  &-avatar {
    width: rem(120);
    height: rem(120);
    border-radius: 50%;
    border: rem(4) solid #ffffff;
    overflow: hidden;
    flex: none;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: block;
    }
  }

  &-information {
    padding-top: rem(14);
    padding-left: rem(30);

    h3 {
      font-size: rem(36);
      font-weight: 600;
      color: #ffffff;
      line-height: rem(34);
    }

    p {
      color: #ffffff;
      height: rem(40);
      font-size: rem(24);
      line-height: rem(36);
      border: 1px solid #ffffff;
      border-radius: rem(20);
      margin-top: rem(18);
      padding: 0 rem(18);

      i {
        float: left;
        margin-right: rem(8);
      }
    }
  }

  &-card {
    background: #ffffff;
    border-radius: rem(16);
    padding: rem(60) rem(30);
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem(-100) rem($margin) rem(20);
    position: relative;
    z-index: 2;
  }

  &-funds {
    .label {
      color: #191b27;
      font-size: rem(28);
      font-weight: 500;
      line-height: 1;
    }

    .value {
      color: #191b27;
      font-size: rem(48);
      font-weight: 600;
      line-height: rem(40);
      margin-top: rem(20);
    }
  }

  &-action {
    font-size: 0;

    .btn {
      margin-left: rem(20);
    }
  }
}

/* 我的信息 */
.information {
  &-data {
    display: flex;
    background: #ffffff;
    padding: rem(30) rem($margin);
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-avatar {
    width: rem(120);
    height: rem(120);
    border-radius: 50%;
    overflow: hidden;
    flex: none;

    img {
      width: 100%;
      height: 100%;
      border: rem(4) solid #e5e5e5;
      border-radius: 50%;
      display: block;
    }
  }

  &-content {
    padding-top: rem(14);
    padding-left: rem(30);

    h3 {
      font-size: rem(36);
      font-weight: 600;
      color: #191b27;
      line-height: rem(34);
    }

    p {
      margin-top: rem(18);
      display: inline-flex;
      align-items: flex-start;
      justify-content: flex-start;

      span {
        display: block;
        color: #bdc1cb;
        height: rem(40);
        font-size: rem(24);
        line-height: rem(36);
        padding: 0 rem(16);
        border: 1px solid #eeeeee;
        border-radius: rem(20);
        margin-right: rem(12);
      }
    }
  }

  &-table {
    margin-top: rem(20);
    background-color: #fff;
    padding-bottom: rem(30);

    &__head {
      padding: rem(22) rem($margin);
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ht-picker__name {
        padding-right: rem(42);

        &::before {
          content: "";
          border-style: solid;
          border-width: rem(10) rem(10) 0;
          border-color: #bdc1cb transparent;
        }
      }
    }

    &__name {
      height: 100%;
      line-height: rem(50);
      font-size: rem(32);
      font-weight: 800;
      color: #191b27;

      &::before {
        content: "";
        width: rem(6);
        height: rem(28);
        background-color: $ht-color-danger;
        border-radius: rem(3);
        display: inline-block;
        vertical-align: top;
        margin-top: rem(10);
        margin-right: rem(20);
      }
    }
  }
}
