.ht-picker {
  display: inline-block;

  &__name {
    height: rem(48);
    font-size: rem(28);
    font-weight: 500;
    color: #191b27;
    text-align: inherit;
    padding-right: rem(32);
    position: relative;

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      text-align: inherit;
      background-color: transparent;
      padding: 0;
    }

    span {
      position: absolute;
      top: 50%;
      right: rem(-6);
      font-size: rem(28);
      color: #bdc1cb;
      margin-left: rem(8);
      transform: translate(0, -50%);
    }
  }

  &__view {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__mask {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.2);
    transition: 0.45s ease-in-out;
    @include hide;
  }

  &__pane {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40vh;
    background-color: #f4f5f6;
    box-shadow: 0 0 rem(12) rgba($color: #000000, $alpha: 0.06);
    transition: 0.45s ease-in-out;
    transform: translate(0, 102%);
    @include hide;
  }

  &__head {
    height: rem(88);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
  }

  &__cancel {
    border: none;
    outline: none;
    font-size: rem(28);
    line-height: rem(48);
    background-color: transparent;
    padding: rem(20) rem($margin);
    color: #8a8e9b;
  }

  &__ensure {
    border: none;
    outline: none;
    font-size: rem(28);
    line-height: rem(48);
    background-color: transparent;
    padding: rem(20) rem($margin);
    color: $ht-color-primary;
  }

  &__body {
    width: 100%;
    height: calc(100% - rem(88));
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: rem(30);
  }

  &__list {
    width: 100%;
    border-top: 0.5px solid #e7e7e7;
  }

  &__item {
    text-align: center;
    padding: rem(20) rem($margin);
    border-bottom: 0.5px solid #e7e7e7;

    &.active {
      color: $ht-color-primary;
    }
  }

  &.active &__mask {
    @include show(1);
  }

  &.active &__pane {
    transform: translate(0, 0);
    @include show(1);
  }
}

.ht-datepicker {
  height: rem(48);
  font-size: rem(28);
  font-weight: 500;
  color: #191b27;
  text-align: inherit;
  padding-right: rem(32);
  position: relative;

  input {
    color: inherit;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    text-align: inherit;
    background-color: transparent;
    padding: 0;
  }

  span {
    position: absolute;
    top: 50%;
    right: rem(-6);
    font-size: rem(28);
    color: #bdc1cb;
    margin-left: rem(8);
    transform: translate(0, -50%);
  }
}
