.ht-form {
  &__item {
    display: flex;
    min-height: rem(92);
    background-color: #fff;
    justify-content: space-between;
    align-items: flex-start;
    padding: rem(22) rem($margin);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: rem($margin);
      right: rem($margin);
      bottom: 0;
      height: 1px;
      background-color: #e7e7e7;
      transform: scaleY(0.5);
    }
  }

  &__label {
    width: 30%;
    height: 100%;
    line-height: rem(48);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__block {
    width: 70%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__input {
    width: 100%;
    height: 100%;
    color: #191b27;
    font-size: rem(28);
    text-align: inherit;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
  }

  &__text {
    width: 100%;
    min-height: 100%;
    color: #191b27;
    font-size: rem(28);
    text-align: inherit;
    background-color: transparent;
    outline: none;
    border: none;
    resize: none;
    padding: 0;
  }

  &__unit {
    color: #191b27;
    font-size: rem(28);
    padding-left: rem(6);
    line-height: 1;
    flex: none;
  }

  &__check {
    height: rem(98);
    padding: rem(25) rem($margin);

    label {
      display: flex;
      height: 100%;
      position: relative;
      align-items: center;
      justify-content: flex-start;
    }

    input[type="checkbox"] {
      appearance: none;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .icon {
      display: block;
      color: #bdc1cb;
      font-size: rem(36);
      line-height: rem(48);
    }

    .text {
      display: block;
      font-size: rem(28);
      color: #191b27;
      margin-left: rem(12);
      line-height: rem(48);

      a {
        color: $ht-color-primary;
      }
    }

    input[type="checkbox"]:checked ~ .icon {
      color: $ht-color-warning;

      &::before {
        content: "\e687";
      }
    }
  }

  &__submit {
    margin-top: rem(60);
    padding: 0 rem($margin);
  }

  &__explain {
    padding: rem(30) rem($margin);

    p {
      font-size: rem(28);
      color: #8a8e9b;
      line-height: rem(42);
    }
  }

  &.left &__block {
    justify-content: flex-start;
    text-align: left;
  }

  &.right &__block {
    justify-content: flex-end;
    text-align: right;
  }
}
