.verify {
  &-flow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0.5px solid #eeeeee;
    padding: rem(60) rem(30);
    position: relative;

    &__item {
      flex: 1;
      padding: 0 rem($margin);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 3;

      .icon {
        display: block;
        width: rem(60);
        height: rem(60);
        color: #bdc1cb;
        font-size: rem(28);
        text-align: center;
        line-height: 1;
        background: #e7e7e7;
        border: rem(6) solid #e7e7e7;
        border-radius: 50%;
        padding: rem(10);
      }

      .name {
        display: block;
        font-size: rem(24);
        color: #191b27;
        text-align: center;
        line-height: rem(30);
        margin-top: rem(20);
      }

      &.active .icon {
        color: #fff;
        background-color: $ht-color-primary;
        border-color: #6994ff;
      }
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: rem(86);
      left: 14%;
      right: 14%;
      height: rem(8);
      background: #e7e7e7;
    }
  }
}

/* 关于我们 */
.about {
  &-view {
    padding: rem(48) rem($margin);
  }

  &-title {
    font-size: rem(36);
    font-weight: 600;
    color: #191b27;
    text-align: center;
    padding-bottom: rem(30);
  }

  &-image {
    line-height: 0;

    img {
      max-width: 100%;
    }
  }

  &-content {
    padding-top: rem(18);
    font-size: rem(28);
    line-height: rem(42);
    text-indent: 2em;
    color: #191b27;

    p {
      margin-top: rem(12);
    }
  }
}

/* 登陆 */
.login {
  &-view {
    padding: rem(60) rem($margin);
  }

  &-link {
    color: #191b27;
    text-align: center;
    padding: rem(49) rem($margin);
    font-size: rem(28);

    a {
      color: $ht-color-primary;
      line-height: rem(34);
      padding: rem(11) 0;
    }
  }

  &-title {
    font-size: rem(44);
    font-weight: 600;
    color: #191b27;
    line-height: 1;
  }

  &-point {
    font-size: rem(28);
    color: #8a8e9b;
    line-height: 1;
    margin-top: rem(20);
  }

  &-input {
    padding-top: rem(120);

    input {
      width: 100%;
      padding: rem(24) 0;
      outline: none;
      border: none;
      font-size: rem(28);
      line-height: rem(46);
      border-bottom: 0.5px solid #eee;
    }
  }
}

/* 弹窗 */
.business {
  &-infos {
    width: 100%;
    height: 100%;
    padding-top: rem(10);
  }

  &-submit {
    padding: rem(72) rem($margin) 0;
  }
}
