/* 提现和重置 */
.charge {
  &-panel {
    padding: rem(30) rem($margin);
  }

  &-title {
    color: #191b27;
    font-size: rem(32);
    font-weight: 800;
    padding-bottom: rem(30);
    line-height: 1;

    small {
      font-weight: 400;
    }
  }

  &-input {
    padding: rem(25) 0;
    border-bottom: 0.5px solid #eeeeee;
    position: relative;

    &__unit {
      position: absolute;
      top: rem(26);
      left: 0;
      font-size: rem(36);
      line-height: rem(48);
      font-weight: bold;
      color: #191b27;
    }

    &__text {
      display: block;
      width: 100%;
      height: 100%;
      font-size: rem(28);
      padding-left: rem(48);
      outline: none;
      border: none;
    }
  }

  &-balance {
    color: #8a8e9b;
    font-size: rem(28);
    font-weight: 400;
    line-height: 1;
    margin-top: rem(30);

    span {
      color: $ht-color-danger;
    }
  }

  &-submit {
    margin-top: rem(60);
  }

  &-issues {
    margin-top: rem(30);

    p {
      font-size: rem(28);
      color: #8a8e9b;
      line-height: rem(42);
    }
  }
}

/* 仲裁中心 */
.arbitral {
  &-list {
    padding: 0 rem($margin);
  }

  &-item {
    margin-top: rem(20);
  }
}

/* 设置中心 */
.setting {
  &-item {
    margin-top: rem(20);

    &:first-child {
      margin-top: 0;
    }
  }
}

/* 意见反馈 */
.feedback {
  &-input {
    height: rem(280);
    padding: rem(20) rem($margin);
    background-color: #fff;

    &__text {
      width: 100%;
      height: 100%;
      font-size: rem(24);
      background-color: transparent;
      outline: none;
      border: none;
      color: #bdc1cb;
      resize: none;
    }
  }

  &-submit {
    padding: rem(60) rem($margin) rem(30);
  }
}

/* 意见反馈 */
.question {
  &-panel {
    background-color: #fff;
    padding: rem(30) rem($margin);
  }

  &-title {
    font-size: rem(32);
    font-weight: bold;
    color: #191b27;
  }

  &-texts {
    margin-top: rem(18);

    p {
      font-size: rem(28);
      color: #646c82;
      line-height: rem(42);
    }
  }
}

/* 消息 */
.message {
  &-view {
    padding: rem(20) rem($margin);
  }

  &-list {
    margin-top: rem(-20);
  }

  &-item {
    margin-top: rem(20);
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 0.5px solid #eeeeee;
    padding: rem(60) 0;

    h3 {
      height: rem(44);
      line-height: rem(44);
      display: inline-flex;
      justify-content: center;

      i {
        display: block;
        color: #fff;
        width: rem(44);
        height: rem(44);
        text-align: center;
        line-height: rem(44);
        background: $ht-color-primary;
        border-radius: 50%;
      }

      span {
        display: block;
        font-size: rem(28);
        font-weight: 600;
        color: #191b27;
        margin-left: rem(20);
      }
    }

    p {
      font-size: rem(60);
      font-weight: 600;
      line-height: rem(45);
      text-align: center;
      margin-top: rem(39);
      color: #191b27;

      small {
        font-size: rem(32);
        padding-right: rem(4);
        vertical-align: 1px;
      }
    }
  }

  &-detail {
    .ht-cell {
      height: rem(98);
      padding-top: rem(25);
      padding-bottom: rem(25);
    }
  }
}

/* 借条详情 */
.detail {
  &-view {
    padding: rem(30) rem($margin);
  }

  &-code {
    font-size: rem(24);
    color: #8a8e9b;
    line-height: rem(30);
  }

  &-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #eeeeee;
    padding: rem(30) 0;

    &__item {
      flex: 2;

      .avar {
        float: left;
        width: rem(90);
        height: rem(90);
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .name {
        margin-left: rem(112);

        strong {
          display: block;
          font-size: rem(28);
          font-weight: bold;
          color: #191b27;
          line-height: rem(30);
        }

        span {
          font-size: rem(24);
          display: inline-block;
          vertical-align: top;
          background: #f0fdf4;
          border: 1px solid #298441;
          border-radius: rem(4);
          margin-top: rem(10);
          padding: 0 rem(8);
          color: #298441;
        }
      }

      .state {
        display: block;
        font-size: rem(28);
        font-weight: bold;
        line-height: 1;
        color: #ff8628;
      }

      .cancel {
        display: block;
        font-size: rem(24);
        color: #646c82;
        padding: rem(14) 0;
        line-height: 1;
      }

      &:nth-child(1) {
        .name strong {
          &::after {
            content: "";
            display: inline-block;
            vertical-align: 0px;
            margin-left: rem(10);
            border-style: solid;
            border-width: rem(10) 0 rem(10) rem(12);
            border-color: transparent #191b27;
          }
        }
      }

      &:nth-child(2) {
        .name strong {
          color: #646c82;
        }

        .name span {
          color: #8a8e9b;
          background: #f5f5f7;
          border: 1px solid #bdc1cb;
        }
      }

      &:nth-child(3) {
        flex: 1;
        text-align: right;
      }
    }
  }

  &-text {
    margin-top: rem(30);

    &__name {
      font-size: rem(32);
      font-weight: 600;
      color: #191b27;
      position: relative;
      padding-left: rem(27);
      padding-bottom: rem(20);

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(6);
        height: rem(28);
        margin-top: rem(-14);
        background-color: $ht-color-danger;
        border-radius: rem(6);
      }
    }

    &__item {
      padding: rem(12) 0;
      line-height: rem(48);
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: $ht-color-primary;
        font-size: rem(28);
      }

      span {
        font-size: rem(28);
        font-weight: 500;

        &:nth-child(1) {
          color: #191b27;
          width: 30%;
        }

        &:nth-child(2) {
          color: #8a8e9b;
          font-weight: 400;
          text-align: right;
          width: 70%;
        }
      }
    }
  }
}
