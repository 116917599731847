.ht-modal {
  &__filter {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .ht-modal__mask {
      top: rem(242 + 88);
    }

    .ht-modal__pane {
      top: rem(242 + 88);
      left: auto;
      right: 0;
      bottom: rem(100);
      width: 75%;
      height: auto;
      transform: translate(100%, 0);
      padding: rem(30);

      h4 {
        color: #333;
        font-size: rem(30);
        font-weight: 600;
        line-height: 1.5;
        padding-bottom: rem(6);
      }

      input {
        width: 100%;
        height: rem(72);
        font-size: rem(24);
        line-height: rem(48);
        border-radius: rem(10);
        padding: rem(12) rem(16);
        background-color: #f1f2f5;
        outline: none;
        border: none;
      }

      .input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        li {
          flex: 3;
          position: relative;
          width: 50%;
        }

        span {
          position: absolute;
        }

        .split {
          flex: 1;
          height: rem(72);
          display: inline-flex;
          justify-content: center;
          align-items: center;

          &::after {
            content: "";
            display: block;
            width: 50%;
            height: 1px;
            background-color: #efefef;
          }
        }
      }

      .fixed {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: rem(-12);
        margin-right: rem(-12);

        li {
          padding: rem(24) rem(12) 0;
        }

        span {
          display: block;
          font-size: rem(24);
          line-height: rem(48);
          padding: rem(12) rem(20);
          background-color: #f1f2f5;
          transition: 0.35s ease-in-out;
          border-radius: rem(10);
        }

        label {
          display: block;
          position: relative;
          height: rem(72);
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          appearance: none;
          margin: 0;
          opacity: 0;
        }

        input:checked + span {
          color: #fff;
          background-color: $ht-active;
        }
      }
    }

    &.active .ht-modal__mask {
      @include show(1);
    }

    &.active .ht-modal__pane {
      transform: translate(0, 0);
      @include show(1);
    }
  }
}

.ht-filter {
  &__search {
    padding-bottom: rem(48);
  }

  &__amount {
    padding-bottom: rem(48);
    .input {
      span {
        left: 0;
        width: rem(48);
        height: 100%;
        font-size: rem(28);
        text-align: center;
        line-height: rem(72);
        padding: 0 rem(12);
      }

      input {
        padding-left: rem(48);
      }
    }
  }

  &__submit {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 48%;
    }
  }

  &__datetime {
    padding-bottom: rem(48);
    .input {
      span {
        right: 0;
        width: rem(56);
        height: 100%;
        font-size: rem(30);
        text-align: center;
        line-height: rem(72);
        padding: 0 rem(12);
      }

      input {
        padding-right: rem(56);
      }
    }
  }
}
