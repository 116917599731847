.ht-notice {
  display: flex;
  height: rem(88);
  padding: rem(20) rem($margin);
  padding-right: rem(84);
  background-color: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    transform: scaleY(0.5);
    background-color: #eee;
  }

  &-name {
    height: rem(48);
    line-height: rem(44);
    padding-right: rem(24);
    position: relative;
    flex: none;

    img {
      height: rem(32);
      vertical-align: middle;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      background-color: #eee;
    }
  }

  &-roll {
    height: 100%;
    padding-left: rem(24);

    a {
      display: block;
      color: $ht-color-title;
      font-size: rem(28);
      line-height: rem(48);
      @include singleLine;
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    color: $ht-color-primary;
    right: rem(24);
    width: rem(36);
    height: rem(36);
    font-size: rem(36);
    line-height: 1;
    margin-top: rem(-18);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
    }
  }
}
