.ht-modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &__mask {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
    transition: 0.45s ease-in-out;
    @include hide;
  }

  &__pane {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40vh;
    background-color: #ffffff;
    box-shadow: 0 0 rem(12) rgba($color: #000000, $alpha: 0.06);
    transition: 0.45s ease-in-out;
    transform: translate(0, 102%);
    @include hide;
  }

  &__head {
    height: rem(88);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f7;
    padding: 0 rem(120);
    position: relative;
  }

  &__title {
    width: 100%;
    text-align: center;
    font-size: rem(32);
    font-weight: 600;
    color: #191b27;
  }

  &__cancel {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    font-size: rem(28);
    line-height: rem(48);
    background-color: transparent;
    padding: rem(20) rem($margin);
    color: $ht-color-primary;
  }

  &__ensure {
    border: none;
    outline: none;
    font-size: rem(28);
    background-color: transparent;
    padding: rem(20) rem($margin);
    color: $ht-color-primary;
  }

  &__body {
    width: 100%;
    height: calc(100% - rem(88));
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: rem(30);
  }

  &__list {
    width: 100%;
    border-top: 0.5px solid #e7e7e7;
  }

  &__item {
    text-align: center;
    padding: rem(20) rem($margin);
    border-bottom: 0.5px solid #e7e7e7;

    &.active {
      color: $ht-color-primary;
    }
  }

  &.active &__mask {
    @include show(1);
  }

  &.active &__pane {
    transform: translate(0, 0);
    @include show(1);
  }

  &__watch.ht-modal &__pane {
    left: 50%;
    width: 80%;
    bottom: 50%;
    height: auto;
    padding: rem(48) rem(54) rem(60);
    transform: translate(-50%, 50%);
    border-radius: rem(20);
  }

  &__watch &__body {
    flex-direction: column;

    .name {
      color: #191b27;
      font-size: rem(32);
      line-height: rem(56);
      white-space: nowrap;
    }

    .text {
      font-size: rem(28);
      line-height: rem(40);
      text-align: center;
      margin-top: rem(20);
    }

    .code {
      width: rem(264);
      height: rem(264);
      padding: rem(12);
      margin-top: rem(54);
      position: relative;

      span {
        display: block;
        line-height: 0;

        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: rem(40);
          height: rem(40);
          border-style: solid;
          border-color: #bddcff;
        }

        &::after {
          right: 0;
          border-width: rem(4) rem(4) 0 0;
          border-radius: 0 rem(12) 0 0;
        }

        &::before {
          left: 0;
          border-width: rem(4) 0 0 rem(4);
          border-radius: rem(12) 0 0 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: rem(40);
        height: rem(40);
        border-style: solid;
        border-color: #bddcff;
      }

      &::after {
        right: 0;
        border-width: 0 rem(4) rem(4) 0;
        border-radius: 0 0 rem(12) 0;
      }

      &::before {
        left: 0;
        border-width: 0 0 rem(4) rem(4);
        border-radius: 0 0 0 rem(12);
      }
    }
  }

  &__watch &__foot {
    margin-top: rem(30);
  }

  &__detail.ht-modal &__pane {
    left: 50%;
    width: 80%;
    bottom: 50%;
    height: auto;
    background: #fff url(../images/detail_bg.jpg) no-repeat center top / 100% auto;
    padding: rem(48) rem(30) rem(60);
    transform: translate(-50%, 50%);
    border-radius: rem(20);
  }

  &__detail &__head {
    height: auto;
    padding-top: rem(42);
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    flex-direction: column;

    .image {
      width: rem(120);
      height: rem(120);
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .brief {
      font-size: rem(32);
      line-height: rem(36);
      margin-top: rem(36);
      padding-bottom: rem(28);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #eeeeee;
      transform: scaleY(0.5);
    }
  }

  &__detail &__body {
    flex-direction: column;
    padding-bottom: 0;

    .name {
      color: #191b27;
      font-size: rem(32);
      line-height: rem(56);
      white-space: nowrap;
    }

    .text {
      font-size: rem(28);
      line-height: rem(40);
      text-align: center;
      margin-top: rem(20);
    }

    .code {
      width: rem(264);
      height: rem(264);
      padding: rem(12);
      margin: rem(54) auto 0;
      position: relative;

      span {
        display: block;
        line-height: 0;

        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: rem(40);
          height: rem(40);
          border-style: solid;
          border-color: #bddcff;
        }

        &::after {
          right: 0;
          border-width: rem(4) rem(4) 0 0;
          border-radius: 0 rem(12) 0 0;
        }

        &::before {
          left: 0;
          border-width: rem(4) 0 0 rem(4);
          border-radius: rem(12) 0 0 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: rem(40);
        height: rem(40);
        border-style: solid;
        border-color: #bddcff;
      }

      &::after {
        right: 0;
        border-width: 0 rem(4) rem(4) 0;
        border-radius: 0 0 rem(12) 0;
      }

      &::before {
        left: 0;
        border-width: 0 0 rem(4) rem(4);
        border-radius: 0 0 0 rem(12);
      }
    }

    .qrcode {
      p {
        color: #191b27;
        font-size: rem(24);
        white-space: nowrap;
        margin-top: rem(36);
      }
    }

    .detail {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-top: rem(15);
      margin-left: rem(-15);
      margin-right: rem(-15);

      li {
        width: 50%;
        font-size: rem(24);
        line-height: rem(24);
        padding: rem(15);
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
      }

      span {
        color: #8a8e9b;
        display: block;

        &:first-child {
          width: 5em;
          color: #191b27;
        }
      }
    }
  }

  &__detail &__exit {
    position: absolute;
    top: rem(-20);
    right: rem(-20);
    width: rem(50);
    height: rem(50);
    border-radius: 50%;
    background-color: #fff;

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(24);
      height: 2px;
      margin-top: -1px;
      margin-left: rem(-12);
      background-color: #2866ff;
      border-radius: 1px;
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}

.ht-datepicker {
  height: rem(48);
  font-size: rem(28);
  font-weight: 500;
  color: #191b27;
  text-align: inherit;
  padding-right: rem(32);
  position: relative;

  input {
    color: inherit;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    text-align: inherit;
    background-color: transparent;
    padding: 0;
  }

  span {
    position: absolute;
    top: 50%;
    right: rem(-6);
    font-size: rem(28);
    color: #bdc1cb;
    margin-left: rem(8);
    transform: translate(0, -50%);
  }
}
