/* common header */
.ht-header {
  display: flex;
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: rem($header-height);
  align-items: center;
  justify-content: center;
  background-color: $header-background;
  box-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.25);
  padding: 0 rem($header-height);

  &__title {
    color: #000000;
    font-size: rem(36);
    font-weight: 600;
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: rem($header-height);
    height: 100%;
    padding: rem($margin);
    font-size: rem(36);
    line-height: rem(40);
    text-align: left;
  }

  &.navbar {
    padding: 0 rem($margin);
    justify-content: flex-start;
  }
}
