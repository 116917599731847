.ht-cell {
  display: flex;
  height: rem(92);
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: rem(22) rem($margin);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: rem($margin);
    right: rem($margin);
    bottom: 0;
    height: 1px;
    background-color: #e7e7e7;
    transform: scaleY(0.5);
  }

  &__name {
    width: 30%;
    height: 100%;
    line-height: rem(48);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__text {
    width: 70%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__icon {
    color: #646c82;
    height: rem(48);
    font-size: rem(36);
    margin-right: rem(12);
  }

  &__title {
    font-size: rem(28);
    font-weight: 500;
    color: #191b27;
  }

  &__value {
    color: #8a8e9b;
    font-size: rem(28);
  }

  &__arrow {
    color: #8a8e9b;
    font-size: rem(24);
    padding-left: rem(16);
    line-height: 1;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}
