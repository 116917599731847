/* common topnav */
.ht-topnav {
  height: rem(90);
  background-color: $ht-color-primary;
  box-sizing: border-box;

  &-pane {
    display: flex;
    height: 100%;
    align-content: flex-start;
    justify-content: flex-start;
  }

  &-ibtn {
    height: 100%;
    @include word($white, rem(28), center, rem(48));
    text-align: center;
    position: relative;
    padding: rem(12) rem($margin);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(70);
      height: rem(5);
      background-color: $white;
      margin-left: rem(-35);
      margin-top: rem(28);
      @include hide;
    }

    &.active {
      font-size: rem(32);
      font-weight: bold;
      line-height: rem(48);

      &:after {
        @include show(1);
      }
    }
  }
}
