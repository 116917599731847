@charset "UTF-8";

/* normalize style */
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  background-color: #f7f7f7;
  font: normal 16px/1.5 "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", \5fae\8f6f\96c5\9ed1, tahoma, simsun, \5b8b\4f53;
  -webkit-text-size-adjust: none;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
select,
button,
textarea {
  font-size: inherit;
  font-family: inherit;
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: normal;
}

ul,
ol,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

i,
em,
address {
  font-style: normal;
}

textarea {
  font-family: inherit;
}

button {
  cursor: pointer;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
}

h1 {
  color: $ht-color-title;
  font-size: 200%;
}

h2 {
  color: $ht-color-title;
  font-size: 150%;
}

h3 {
  color: $ht-color-title;
  font-size: 100%;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
}

::-webkit-input-placeholder {
  color: $ht-color-placeholder;
}

::-moz-input-placeholder {
  color: $ht-color-placeholder;
}

:-moz-input-placeholder {
  color: $ht-color-placeholder;
}

::-ms-input-placeholder {
  color: $ht-color-placeholder;
}

@media all and (min-width: 320px) {
  html {
    font-size: 32px;
  }
}

@media all and (min-width: 360px) {
  html {
    font-size: 36px;
  }
}

@media all and (min-width: 375px) {
  html {
    font-size: 37.5px;
  }
}

@media all and (min-width: 384px) {
  html {
    font-size: 38.4px;
  }
}

@media all and (min-width: 412px) {
  html {
    font-size: 41.2px;
  }
}

@media all and (min-width: 411px) {
  html {
    font-size: 41.1px;
  }
}

@media all and (min-width: 413px) {
  html {
    font-size: 41.3px;
  }
}

@media all and (min-width: 414px) {
  html {
    font-size: 41.4px;
  }
}

@media all and (min-width: 480px) {
  html {
    font-size: 48px;
  }
}

@media all and (min-width: 540px) {
  html {
    font-size: 54px;
  }
}

@media all and (min-width: 568px) {
  html {
    font-size: 56.8px;
  }
}

@media all and (min-width: 667px) {
  html {
    font-size: 66.7px;
  }
}

@media all and (min-width: 736px) {
  html {
    font-size: 73.6px;
  }
}

@media all and (min-width: 750px) {
  html {
    font-size: 75px;
  }
}

@media all and (min-width: 768px) {
  html {
    font-size: 76.8px;
  }
}
