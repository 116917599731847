.ht-mainnav {
  padding: rem(10) rem($margin) rem(2);
  background-color: $ht-color-primary;
  display: flex;
  align-items: center;
  justify-content: center;

  &__list {
    height: rem(60);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #ffffff;
    border-radius: rem(8);
    overflow: hidden;
  }

  &__item {
    min-width: rem(150);
    border-left: 0.5px solid #ffffff;

    a {
      display: block;
      height: rem(60);
      color: #fff;
      font-size: rem(28);
      text-align: center;
      line-height: rem(60);
      padding: 0 rem(30);
    }

    &.active {
      background-color: #fff;

      a {
        color: $ht-color-primary;
      }
    }

    &:first-child {
      border-left: none;
    }
  }
}
