.ht-card {
  display: block;
  background: #ffffff;
  border-left: rem(8) solid #dcf1ff;
  border-radius: rem(16);

  &__head {
    display: flex;
    border-bottom: 0.5px solid #eeeeee;
    justify-content: space-between;
    align-items: center;
    padding: rem(20) rem(30);
  }

  &__body {
    padding: rem(20) rem(30);
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: rem(24);
      font-weight: 500;
      color: #646c82;
      line-height: rem(39);
    }
  }

  &__foot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: rem(20) rem(30) rem(30);

    .btn {
      height: rem(54);
      padding-top: 0;
      padding-bottom: 0;
      font-size: rem(24);
      border-radius: rem(27);

      i {
        font-size: rem(24);
      }
    }
  }

  &__avatar {
    height: rem(44);
    line-height: rem(44);

    img {
      float: left;
      width: rem(44);
      height: rem(44);
      border-radius: 50%;
      background-color: #000;
    }

    span {
      font-size: rem(28);
      font-weight: bold;
      color: #191b27;
      display: inline-block;
      vertical-align: top;
      padding-left: rem(20);
    }
  }

  &__status {
    height: rem(44);
    line-height: rem(44);

    span {
      font-size: rem(24);
      font-weight: 500;

      &.danger {
        color: $ht-color-danger;
      }

      &.warning {
        color: $ht-color-warning;
      }
    }
  }
}
