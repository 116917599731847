.ht-filter {
  height: rem(80);
  background-color: #fff;
  box-shadow: 0 0 rem(10) rgba($color: #000000, $alpha: 0.05);

  &__list {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 rem($margin);
  }

  &__item {
    flex: 1;
    width: 100%;
    height: 100%;

    a {
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: rem(28);
      color: #191b27;
    }

    i {
      color: #8a8e9b;
      font-size: inherit;
      margin-left: rem(8);
    }

    &.active a {
      color: $ht-color-primary;
    }
  }
}
