@charset "UTF-8";
$header-color: #333;
$header-height: 88.008;
$header-background: #f5f5f5;
$column-background: #fcf7fb;

// 网站主色
$white: #ffffff;
$ht-active: #2866ff;
$ht-color-default: #1678ff;
$ht-color-primary: #2866ff;
$ht-color-warning: #ff8628;
$ht-color-success: #0fda8b;
$ht-color-danger: #ff412c;

// 网站字体颜色
$ht-color-title: #333333;
$ht-color-brief: #666666;
$ht-color-affix: #999999;
$ht-color-border: #e8e8e8; // 边框颜色
$ht-color-background: #f7f7f7; // 背景色
$ht-color-placeholder: #bdc1cb; // 表单提示文字颜色

// 网站编辑
$ht-border: 1px solid $ht-color-border;
$ht-spacing: 15px;
$margin: 24;
