.ht-tabbar {
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 rem(-8) rem(8) rgba($color: #000000, $alpha: 0.05);

  &-list {
    display: flex;
    width: 100%;
    height: rem(100);
    background-color: #fff;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-item {
    flex: 1;
    height: 100%;
    text-align: center;

    a {
      display: block;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: rem(12) 0;
      width: 100%;
    }
  }

  &-icon {
    display: block;
    width: rem(44);
    height: rem(44);
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-name {
    display: block;
    width: 100%;
    color: $ht-color-brief;
    font-size: rem(22);
    line-height: 1;
    margin-top: rem(10);
  }

  &-item.active &-name {
    color: #2866ff;
  }
}
