@charset "UTF-8";

// 百分比
@function ptr($a, $b) {
  @return percentage(math.div(ceil(math.div($a, $b) * 1000000000), 1000000000));
}

@function rem($px) {
  @return math.div(ceil(math.div($px, 75) * 1000000), 1000000) * 1rem;
}

// 行高
@function lht($a, $b) {
  @return math.div(ceil(math.div($a, $b) * 100), 100);
}

// 隐藏
@mixin hide {
  visibility: hidden;
  opacity: 0;
}

// 显示
@mixin show($s) {
  visibility: visible;
  opacity: $s;
}

// 行内块元素
@mixin dibk($a) {
  display: inline-block;
  vertical-align: $a;
}

// 图片布局
// $color 字体颜色 | $size 字体大小 | $align 对齐方式 | $height 行高度
@mixin word($color, $size, $align, $height) {
  @if $color !=0 {
    color: $color;
  }

  @if $size !=0 {
    font-size: $size;
  }

  @if $align !=0 {
    text-align: $align;
  }

  @if $height !=0 {
    line-height: math.div(ceil(math.div($height, $size) * 100), 100);
  }
}

// 清除浮动
@mixin clear {
  &:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
  }
}

// 图片布局
// $height 图片高度 | $width 图片宽度 | $position 图片定位
@mixin image($height, $width, $position) {
  padding-bottom: ptr($height, $width);
  position: $position;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

// 多行省略
// $rows 行数
@mixin multiLine($rows) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
}

// 单行省略
@mixin singleLine {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// 栅格系统
// $column 栅格列数 | $space 栅格间隙
@mixin gridSystem($column, $space) {
  .ht {
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$space;
      margin-right: -$space;
      align-items: flex-start;
      justify-content: flex-start;

      & > [class*="ht-col-"] {
        padding-left: $space;
        padding-right: $space;
        box-sizing: border-box;
      }
    }

    @for $var from 1 through $column {
      &-col-#{$var} {
        width: ptr($var, $column);
      }
    }
  }
}

// 滚动条
// $width 轨道宽度 | $width 轨道颜色 | $width 轨道进度条颜色
@mixin scrollBar($width, $trackColor, $barcolor) {
  &::-webkit-scrollbar-track-piece {
    background: $trackColor;
  }

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background: $barcolor;
    border-radius: 10px;
  }
}

// 滚动条
// $width 轨道宽度 | $width 轨道颜色 | $width 轨道进度条颜色
@mixin centered($top, $left, $zIndex) {
  position: absolute;
  z-index: $zIndex;
  top: $top;
  left: $left;
  transform: translate(-$top, -$left);
}
