.ht-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  &__th {
    font-size: rem(24);
    font-weight: 500;
    color: #8a8e9b;
    text-align: center;
    line-height: rem(48);
    padding: rem(8) rem(20);
    vertical-align: middle;
    border-bottom: 0.5px solid #e7e7e7;
  }

  &__td {
    font-size: rem(24);
    font-weight: 500;
    color: #000000;
    text-align: center;
    line-height: rem(48);
    padding: rem(8) rem(20);
    vertical-align: middle;
    border-bottom: 0.5px solid #e7e7e7;
  }

  &__head {
    background-color: #fff;
  }

  &__body {
    background-color: #fff;

    &:nth-child(2n + 1) {
      background-color: #f5f5f7;
    }
  }
}
