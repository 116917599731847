.ht-message {
  display: block;
  background-color: #fff;
  box-shadow: 0 0 rem(6) rgba($color: #000000, $alpha: 0.03);
  border-radius: rem(16);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(20) rem(30);
  }

  &__body {
    padding: rem(20) rem(30) rem(30);
  }

  &__foot {
    border-top: 0.5px solid #eeeeee;
    padding: rem(20) rem(30) rem(30);

    p {
      font-size: rem(24);
      color: #646c82;
      line-height: rem(39);
    }
  }

  &__title {
    height: rem(44);
    line-height: rem(44);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    i {
      display: block;
      color: #fff;
      width: rem(44);
      height: rem(44);
      text-align: center;
      line-height: rem(44);
      background: $ht-color-primary;
      border-radius: 50%;
    }

    span {
      display: block;
      font-size: rem(28);
      font-weight: 600;
      color: #191b27;
      margin-left: rem(20);
    }
  }

  &__state {
    font-size: rem(28);
    font-weight: 500;
    line-height: rem(44);

    &.warning {
      color: $ht-color-warning;
    }

    &.primary {
      color: $ht-color-warning;
    }

    &.danger {
      color: $ht-color-warning;
    }
  }

  &__type {
    font-size: rem(24);
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: #646c82;
  }

  &__cost {
    font-size: rem(44);
    font-weight: 600;
    line-height: rem(36);
    text-align: center;
    margin-top: rem(20);
    color: #191b27;

    small {
      font-size: rem(24);
      padding-right: rem(4);
      vertical-align: 1px;
    }
  }

  &__tips {
    font-size: rem(24);
    font-weight: 600;
    color: #191b27;
    line-height: 1;
    margin-top: rem(60);
  }
}
