@include gridSystem(12, rem(15));

.ht {
  &-fixed {
    position: fixed;
    z-index: 97;
    top: rem($header-height);
    left: 0;
    width: 100%;
  }

  &-layout {
    width: 100%;
    padding-top: rem($header-height);
    padding-bottom: rem(120);

    &.bgcolor {
      background-color: $column-background;
    }

    &.fullscreen {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #fff;
      padding-bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-column {
    padding: rem(80) rem($margin);

    &.bgcolor {
      background-color: $column-background;
    }
  }

  &-more {
    display: block;
    width: rem(60);
    height: rem(12);
    padding: rem(2) 0;
    position: relative;
    font-size: 0;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(60);
      height: rem(1);
      margin-top: rem(4);
      background-color: $ht-active;
      transform: translate(-50%, 0);
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: rem(2);
      height: rem(1);
      width: rem(20);
      margin-top: rem(4);
      margin-left: rem(10);
      background-color: $ht-active;
      transform-origin: 100% 50%;
      transform: rotate(25deg);
    }
  }
}

// 重置标点
.swiper-container {
  .swiper-pagination {
    height: rem(16);
    line-height: rem(16);
    bottom: 0;
  }

  .swiper-pagination-bullet {
    vertical-align: top;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $ht-active;
  }
}

[class*="htfont-"] {
  font-family: "htfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  height: rem(72);
  min-width: rem(152);
  line-height: rem(48);
  display: inline-block;
  vertical-align: top;
  text-align: center;
  border-radius: rem(8);
  padding: rem(10) rem(30);
  transition: 0.25s ease-in-out;
  border: 1px solid #000;
  font-size: rem(32);
  font-weight: 500;

  &-primary {
    color: #ffffff;
    background-color: $ht-color-primary;
    border-color: $ht-color-primary;
  }

  &-primary.btn-plain {
    color: $ht-color-primary;
    background-color: #fff;
  }

  &-block {
    width: 100%;
    height: rem(96);
    line-height: rem(72);
    border-radius: rem(16);
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;
  }
}
